import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

let defaultLightThemes = {
    primary: '#009098',
    secondary: colors.teal.lighten4,
    accent: colors.indigo.base,
    error: '#dd0022',
    success: '#009098',
}
const vuetify = new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: defaultLightThemes
        }
    }
})

export function setUserPresetLightThemes(lightThemeOptions) {
    if (lightThemeOptions) {
        vuetify.framework.theme.themes.light = {
            ...vuetify.framework.theme.themes.light,
            ...lightThemeOptions
        }
    }
}

export default vuetify